<template :key="componentKey">
  <b-card>
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form class="mt-2 keyword_form" @submit.prevent>
        <div class="top col-12 translation_box">
          <b-row
            v-for="(item, index) in keywordDetails[idx].translation"
            :key="index"
          >
            <b-col>
              <div>
                {{ item.label }}
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group :label="`${index}`" label-for="mc-country">
                <validation-provider
                  #default="{ errors }"
                  :name="`${index}`"
                  rules="required"
                >
                  <b-form-input
                    v-model="item.value"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="`${item.label}`"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </div>

        <!-- alert -->
        <!-- <b-col cols="12" class="mt-75" /> -->
        <!--/ alert -->

        <div class="btm translation_sub_box">
          <b-row class="float-right">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="loading"
              @click="editKeywords"
            >
              Save changes
              <div v-if="loading" class="spinner-border spinner-border-sm" />
            </b-button>
          </b-row>
        </div>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
	BButton,
	BForm,
	BRow,
	BCol,
	BCard,
	BFormGroup,
	BFormInput
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "vue2-datepicker/index.css";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@core/utils/validations/validations";
import { mapState } from "vuex";
import { resConditionCheck } from "@/helpers/constant";
import translationService from "@/services/keyword-translation/translation.service";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
	components: {
		BButton,
		BForm,
		BRow,
		BCol,
		BCard,
		ValidationObserver,
		ValidationProvider,
		BFormGroup,
		BFormInput
	},
	directives: {
		Ripple
	},
	props: {
		keywordDetails: {
			type: Array,
			default: () => []
		},
		idx: {
			type: Number,
			default: () => ""
		},
		keywordForm: {
			type: Object,
			default: () => []
		},
		tabId: {
			type: Number,
			required: true
		},
		wlCode: {
			type: Number,
			default: () => 1
		}
	},

	data() {
		return {
			required,
			componentkey: 0,
			loading: false,
			form: {
				value: []
			},
			keyword: {
				keyword_value: ""
			}
		};
	},

	provide: {
		username: "Test"
	},

	computed: {
		...mapState(["auth"])
	},

	methods: {
		editKeywords() {
			this.$refs.simpleRules.validate().then(success => {
				if (success) {
					this.loading = true;
					const { translation } = this.keywordDetails.find(
						e => e.id === this.tabId
					);
					translationService
						.editKeywords(this.tabId, {
							translation: btoa(
								unescape(encodeURIComponent(JSON.stringify(translation)))
							)
						}, this.wlCode)
						.then(res => {
							if (resConditionCheck(res.status) && res.data.data) {
								setTimeout(() => {
									this.loading = false;
								}, 3000);
								this.$toast({
									component: ToastificationContent,
									props: {
										title: res.data.message,
										icon: "CheckIcon",
										variant: "success"
									}
								});
							}
						})
						.catch(error => {
							setTimeout(() => {
								this.loading = false;
							}, 3000);
							const errorData = errorResponseHandler(error);
							this.$toast({
								component: ToastificationContent,
								props: {
									title: errorData,
									icon: "X-CircleIcon",
									variant: "danger"
								}
							});
						});
				}
			});
		}
	}
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style>
.tab-content .tab-pane {
	height: calc(128vh - 360px);
}
.tab-content .tab-pane .card {
	height: 100%;
}
.translation_box {
	overflow-x: hidden;
}
.translation_sub_box {
	position: relative;
}
.keyword_form {
	height: calc(100% - 86px);
	display: flex;
	justify-content: space-between;
	flex-flow: column;
}
</style>
