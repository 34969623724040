<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="5">
          <label>Filter by WhiteLabel</label>
          <v-select
            v-model="wlCode"
            :options="whitelabelList"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            class="w-100"
            :reduce="(val) => val.value"
            placeholder="Select Whitelabel"
            :clearable="true"
          />
        </b-col>
        <b-col md="5">
          <validation-provider
            #default="{ errors }"
            name="Language"
            rules="required"
          >
            <b-form-group
              class="md-2"
              label="Select Languages"
              label-for="mc-select-language"
              :state="errors.length > 0 ? false : null"
            >
              <v-select
                v-model="keywordForm.selectedLanguage"
                :options="keywordForm.selectedLanguages"
                class="language"
                placeholder="Select Languages"
                :state="errors.length > 0 ? false : null"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="(val) => val.value"
                :clearable="false"
                @input="getLanguageId($event)"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col md="2">
          <label for="" class="d-block invisible">Add Keyword</label>
          <b-button
            variant="primary"
            class="float-right"
            v-b-modal.modal-add-keyword
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          >
            Add Keyword
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-tabs
      v-if="!contentLoading"
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <div v-for="(item, index) in items" :key="index">
        <b-tab @click="checkIndex(index, item.id)">
          <template #title>
            <feather-icon icon="ListIcon" size="18" class="mr-50" />
            <span class="font-weight-bold">{{ item.pageLabel }}</span>
          </template>
          <keyword-component
            ref="simpleRules"
            @getKeywords="getKeywords"
            :keyword-details="items"
            :idx="index"
            :keyword-form="keywordForm"
            :tab-id="tabId"
            :wlCode="wlCode"
          />
        </b-tab>
      </div>
    </b-tabs>
    <div v-if="contentLoading">
      <Loader />
    </div>
    <b-modal
      id="modal-add-keyword"
      title="Add New Keyword Translation"
      cancel-title="Close"
      ok-title="Save"
      size="lg"
      cancel-variant="outline-secondary"
      no-close-on-backdrop
      @ok="AddNewKeywordTranslation"
      @cancel="resetAddForm()"
      @close="resetAddForm()"
    >
      <validation-observer ref="keywordAddRules">
        <b-form @submit.prevent>
          <b-row col>
            <b-col md="12">
              <b-col md="12">
                <validation-provider
                  #default="{ errors }"
                  name="Page Name"
                  rules="required"
                >
                  <b-form-group
                    label="Page Name"
                    label-for="mc-select-page-name"
                    :state="errors.length > 0 ? false : null"
                  >
                    <v-select
                      v-model="addKewordForm.page_name"
                      :options="pages"
                      class="page-name"
                      placeholder="Select Page Name"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :reduce="(val) => val.value"
                      :clearable="false"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12">
                <b-form-group label="Key" label-for="mc-Key">
                  <validation-provider
                    #default="{ errors }"
                    name="Key"
                    rules="required|alphaNumeric|noWhiteSpaces"
                  >
                    <b-form-input
                      v-model="addKewordForm.key"
                      class="Key"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Key"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Label" label-for="mc-Label">
                  <validation-provider
                    #default="{ errors }"
                    name="Label"
                    rules="required"
                  >
                    <b-form-input
                      v-model="addKewordForm.label"
                      class="Label"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Label"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group label="Value" label-for="mc-Value">
                  <validation-provider
                    #default="{ errors }"
                    name="Value"
                    rules="required"
                  >
                    <b-form-textarea
                      v-model="addKewordForm.value"
                      class="Value"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Value"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BFormGroup,
  BRow,
  BCol,
  BCard,
  BButton,
  BModal,
  BForm,
  VBModal,
  BFormInput,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import languageService from "@/services/language/language.service";
import { LanguageEnum, resConditionCheck } from "@/helpers/constant";
import errorResponseHandler from "@/services/errorHandler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import translationService from "@/services/keyword-translation/translation.service";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import KeywordComponent from "./KeywordComponent.vue";
import whitelabelService from "@/services/whitelabel/whitelabel.service";
import axios from "axios";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BTabs,
    BTab,
    KeywordComponent,
    vSelect,
    ValidationProvider,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    Loader,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormTextarea,
    ValidationObserver,
  },

  directives: {
    "b-modal": VBModal,
    Ripple,
  },

  data() {
    return {
      contentLoading: true,
      accountKey: 0,
      keywordForm: {
        selectedLanguages: [],
        value: [],
        selectedLanguage: LanguageEnum.English || ""
      },
      languageId: LanguageEnum.English,
      idx: "",
      tabId: "",
      keyword: {
        keyword_value: ""
      },
      items: [],
      wlCode: 1,
      whitelabelList: [],
      wl_code: axios.defaults.headers.wl_code,
      pages: [],
      addKewordForm: {
        page_name: "",
        key: "",
        label: "",
        value: "",
      },
    };
  },

  watch: {
    wlCode: {
      async handler(val) {
        if (val === null) {
          this.wlCode = 1;
        }
        await this.getLanguageDropdown(this.wlCode);
        await this.getKeywords(this.languageId, this.wlCode);
      },
    },
  },

  async mounted() {
    await this.getKeywords(this.languageId, this.wlCode);
    await this.getLanguageDropdown();
    if (this.wl_code == 1) {
      await this.getWhitelabelList();
    }
  },

  methods: {
    async getLanguageDropdown(wlCode) {
      try {
        const res = await languageService.getLanguageDropdown(wlCode);
        if (resConditionCheck(res.status) && res.data.data) {
          this.keywordForm.selectedLanguages = res.data.data.map((e) => ({
            label: e.name,
            value: e.id,
          }));
          this.languageId =
            res.data.data && res.data.data[0]
              ? res.data.data[0]?.id
              : this.languageId;
          this.keywordForm.selectedLanguage =
            res.data.data && res.data.data[0]
              ? res.data.data[0]?.name
              : this.languageId;
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    async getKeywords(id, wlCode) {
      this.contentLoading = true;
      try {
        const res = await translationService.getKeywords(id, wlCode);
        if (resConditionCheck(res.status) && res.data.data) {
          this.contentLoading = false;
          this.items = res.data.data;
          this.tabId = this.items[0].id;
          this.pages = res.data.data.map((e) => {
            return {
              label: e.pageLabel,
              value: e.pageName,
            };
          });
        }
      } catch (error) {
        const errorData = errorResponseHandler(error);
        this.contentLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: errorData,
            icon: "X-CircleIcon",
            variant: "danger",
          },
        });
      }
    },

    getLanguageId(event) {
      this.getKeywords(event, this.wlCode);
    },

    checkIndex(index, id) {
      this.tabId = id;
      this.idx = index;
      this.keywordForm.value = [];
    },

    async getWhitelabelList() {
      this.isLoaded = false;
      const payload = {
        no_of_result: 0,
        page: 1,
        order: "ASC",
        order_by: "name",
      };
      await whitelabelService
        .getWhitelableList(payload)
        .then((res) => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.isLoaded = true;
            this.whitelabelList = res.data.data.map((item) => {
              // if(!item.isDeleted) {
              return {
                label: item.name,
                value: item.id,
              };
              // }
            });
            this.whitelabelList.push({ label: "Beontrips", value: 1 });
          }
        })
        .catch((error) => {
          console.log("Error", error);
          const errorData = errorResponseHandler(error);
          this.$toast({
            component: ToastificationContent,
            props: {
              title: errorData,
              icon: "X-CircleIcon",
              variant: "danger",
            },
          });
        });
    },

    getWhiteLabelName(wlCode) {
      let label = this.whitelabelList.find((wl) => wl.value === wlCode)?.label;
      return label ? label : "Beontrips";
    },

    async AddNewKeywordTranslation(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.$refs.keywordAddRules.validate().then(async (success) => {
        if (success) {
          this.loading = true;
          await translationService
            .addNewKeywordTranslation(this.addKewordForm)
            .then(async(res) => {
              if (resConditionCheck(res.status) && res.data) {
                this.loading = false;
                this.$nextTick(() => {
                  this.$emit("on-submit");
                  this.$bvModal.hide("modal-add-keyword");
                  this.resetAddForm();
                });
                await this.getKeywords(this.languageId, this.wlCode);
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: res.data.message,
                    icon: "X-CircleIcon",
                    variant: "success",
                  },
                });
              }
            })
            .catch((error) => {
              this.loading = false;
              const errorData = errorResponseHandler(error);
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorData,
                  icon: "X-CircleIcon",
                  variant: "danger",
                },
              });
            });
        }
      });
    },
    resetAddForm() {
      this.addKewordForm.page_name = '';
      this.addKewordForm.key = '';
      this.addKewordForm.label = '';
      this.addKewordForm.value = '';
    }
  },
};
</script>
